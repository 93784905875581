<template>
  <div class="image-stack" @click="handleClick">
    <canvas ref="canvas" :width="containerWidth" :height="containerHeight" style="display: none;"></canvas>
    <!-- 以 z-index 控制图片层叠顺序 -->
    <img v-for="(image, index) in images" :src="image" :style="{ 'z-index': images.length - index }" :key="index" alt="Image">
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        'http://sellbabycrm.oss-cn-beijing.aliyuncs.com/1699235299024100-1%E4%B8%BB%E6%9F%9C.png?x-oss-process=style/scrm_cs',
        'https://crmfile.sellbaby.net/media/2023/11/05/814b4bfcdeea43f3988bb59fe33d4934.png?x-oss-process=style/scrm_cs',
        // 添加更多图片路径
      ],
      containerWidth: 500, // 根据实际情况调整容器宽度
      containerHeight: 500, // 根据实际情况调整容器高度
    };
  },
  methods: {
    handleClick(event) {
  const x = event.clientX;
  const y = event.clientY;

  // 获取canvas元素和上下文
  const canvas = this.$refs.canvas;
  const ctx = canvas.getContext('2d');

  // 清空canvas
  ctx.clearRect(0, 0, this.containerWidth, this.containerHeight);

  // 使用Promise.all确保所有图片都加载完成
  const loadImagePromises = this.images.map((imageUrl) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = imageUrl;
      image.setAttribute('crossOrigin', '');
    });
  });

  Promise.all(loadImagePromises)
    .then((images) => {
      // 逐层绘制图片到canvas
      for (let i = images.length - 1; i >= 0; i--) {
        ctx.drawImage(images[i], 0, 0, this.containerWidth, this.containerHeight);
        // 获取点击位置的像素颜色
        const pixel = ctx.getImageData(x, y, 1, 1).data;

        // 判断颜色是否为透明
        if (pixel[0] === 0 && pixel[1] === 0 && pixel[2] === 0 && pixel[3] === 0) {
          // 如果是透明色，则继续查找下一层
          continue;
        } else {
          // 如果不是透明色，则执行相应操作
          console.log('Clicked on image:', this.images[i]);
          return;
        }
      }

      // 如果点击在空白区域，则执行相应操作
      console.log('Clicked on empty space');
    })
    .catch((error) => {
      console.error('Error loading images:', error);
    });
},

  },
};
</script>

<style scoped>
.image-stack {
  position: relative;
  width: 500px; /* 根据实际情况调整容器宽度 */
  height: 500px; /* 根据实际情况调整容器高度 */
}

.image-stack img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例并填充容器 */
}
</style>
